import { Field } from 'component/Field'
import { FieldStore } from 'form/store/FieldStore'
import { useLatestCallback } from 'hook/useLatestCallback'
import { observer } from 'mobx-react-lite'
import { ReactNode, useState } from 'react'

type Props<V = unknown> = {
  children: ReactNode
  field: FieldStore<V>
  label: string
  onFocus?(focus: boolean): void
  small?: boolean
}

export const FormField = observer(<V, >(props: Props<V>) => {
  const { children, field, label, small } = props
  const [focus, setFocus] = useState(false)
  const error = !focus && field.error

  const onFocus = useLatestCallback((focus: boolean) => {
    setFocus(focus)
    field.onFocus(focus)
    props.onFocus?.(focus)
  })

  return <Field label={label} error={error} small={small} onFocus={onFocus}>
    {children}
  </Field>
})
