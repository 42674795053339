// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[2].use[2]!./Glass.module.scss");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Sticker-sticker-4432{z-index:1;position:absolute;width:fit-content;height:fit-content;margin:auto;border-radius:var(--ui-border-radius-half);overflow:hidden}.Sticker-sticker-4432.Sticker-horizontal-4432{display:flex}.Sticker-sticker-4432.Sticker-vertical-4432{display:flex;flex-direction:column}.Sticker-none-4432{pointer-events:none}.Sticker-sticker-4432:before{z-index:-1;content:"";position:absolute;inset:0;background-color:var(--ui-background-color);opacity:0;transition:opacity 200ms ease-in-out}.${___CSS_LOADER_ICSS_IMPORT_0___.locals["glass"]}:hover .Sticker-sticker-4432:before{opacity:60%}.${___CSS_LOADER_ICSS_IMPORT_0___.locals["glass"]} .Sticker-sticker-4432:hover:before{opacity:100%}.Sticker-hidden-4432{z-index:0;opacity:0;transition:opacity 200ms ease-in-out}@media(hover: none){.Sticker-hidden-4432{opacity:50%}}.${___CSS_LOADER_ICSS_IMPORT_0___.locals["glass"]}:hover .Sticker-hidden-4432{opacity:100%}.Sticker-highlight-4432{z-index:0;opacity:100%}.Sticker-highlight-4432:after{z-index:-1;content:"";position:absolute;inset:0;background:radial-gradient(closest-side, var(--ui-background-color), transparent);opacity:100%;transition:opacity 200ms ease-in-out}.${___CSS_LOADER_ICSS_IMPORT_0___.locals["glass"]}:hover .Sticker-highlight-4432:after{opacity:0}.Sticker-full-4432{width:auto;height:auto}`, "",{"version":3,"sources":["webpack://./src/component/Glass/Sticker.module.scss"],"names":[],"mappings":"AAEA,sBACE,SAAA,CACA,iBAAA,CACA,iBAAA,CACA,kBAAA,CACA,WAAA,CACA,0CAAA,CACA,eAAA,CAGF,8CACE,YAAA,CAGF,4CACE,YAAA,CACA,qBAAA,CAGF,mBACE,mBAAA,CAGF,6BACE,UAAA,CACA,UAAA,CACA,iBAAA,CACA,OAAA,CACA,2CAAA,CACA,SAAA,CACA,oCAAA,CAGF,iFACE,WAAA,CAGF,iFACE,YAAA,CAGF,qBACE,SAAA,CACA,SAAA,CACA,oCAAA,CAGF,oBACE,qBACE,WAAA,CAAA,CAIJ,yEACE,YAAA,CAGF,wBACE,SAAA,CACA,YAAA,CAGF,8BACE,UAAA,CACA,UAAA,CACA,iBAAA,CACA,OAAA,CACA,iFAAA,CACA,YAAA,CACA,oCAAA,CAGF,kFACE,SAAA,CAGF,mBACE,UAAA,CACA,WAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"glass": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["glass"]}`,
	"sticker": `Sticker-sticker-4432`,
	"horizontal": `Sticker-horizontal-4432`,
	"vertical": `Sticker-vertical-4432`,
	"none": `Sticker-none-4432`,
	"hidden": `Sticker-hidden-4432`,
	"highlight": `Sticker-highlight-4432`,
	"full": `Sticker-full-4432`
};
module.exports = ___CSS_LOADER_EXPORT___;
