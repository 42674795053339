import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Back } from 'feature/back/Back'
import { ReactNode } from 'react'
import css from './PageHeader.module.scss'

type Props = {
  children?: ReactNode
  title?: string | ReactNode
  onBack?: () => void
}

export const PageHeader = ({ title, children, onBack }: Props) => {
  return <Space className={css.pageHeader} wide opposite gap={[26, 26]}>
    <Space gap={24}>
      {onBack && <Back simple action={onBack} />}
      <Typo size={24} semi>
        {title}
      </Typo>
    </Space>
    <Space>{children}</Space>
  </Space>
}
