import { Space } from 'component/Space'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { TotalCard } from 'saas/page/statistics/total/TotalCard'
import { totals } from 'saas/store/stat/TotalsStore'

export const Totals = observer(() => {
  const { users, inferences, widgetOpen, share } = totals.totals ?? {}

  return <Space gap={16} spread>
    <TotalCard num={users} unit={i18n('total.Users')}
      tooltip={i18n('total.UsersTooltip')} />
    <TotalCard num={inferences} unit={i18n('total.Generations')}
      tooltip={i18n('total.GenerationsTooltip')} />
    <TotalCard num={widgetOpen} unit={i18n('total.WidgetOpen')}
      tooltip={i18n('total.WidgetOpenTooltip')} />
    <TotalCard num={share} unit={i18n('total.Share')}
      tooltip={i18n('total.ShareTooltip')} />
  </Space>
})
