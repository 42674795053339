import clsx from 'clsx'
import { Button } from 'component/Button'
import { ClickAction } from 'hook/useOnClickAction'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import css from './Back.module.scss'

type Props = {
  simple?: boolean
  action?: ClickAction | false
}

export const Back = ({ simple, action }: Props) => {
  return <Button
    className={clsx(css.button, simple && css.simple)}
    link
    action={action}
    icon={<Icon name="arrow_back" />}>
    {!simple && i18n('common.Back')}
  </Button>

}
