import { AxisTickProps } from '@nivo/axes'
import { chart } from 'saas/store/stat/ChartStore'
import { last } from 'util/array'
import { formatDate } from 'util/date'
import css from './DateTick.module.scss'

export function renderDateTick(props: AxisTickProps<number>) {
  const { series, group } = chart
  const { x, y, textX, textY, textBaseline, value } = props
  const transform = `translate(${textX}, ${textY}) rotate(0)`
  const format = group === 'month' ? 'MMMM' : group === 'week' ? 'DD+ MMM' : 'DD MMM'
  const date = formatDate(new Date(value), format)
  const year = formatDate(new Date(value), 'YYYY')
  const latest = last(series[0]?.data ?? [])
  const end = latest?.x === value
  const textAnchor = end ? 'end' : 'start'

  return <g className={css.tick} transform={`translate(${x}, ${y})`}>
    <text dominantBaseline={textBaseline} textAnchor={textAnchor} transform={transform}>
      <tspan className={css.date} x={0} dy={0}>
        {date}
      </tspan>
      <tspan className={css.year} x={0} dy={16}>{year}</tspan>
    </text>
  </g>
}
