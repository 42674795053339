import { PageHeader } from 'component/PageHeader'
import { Divider } from 'component/Space/Divider'
import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { ChartView } from 'saas/page/statistics/chart/ChartView'
import { TopProducts } from 'saas/page/statistics/items/TopProducts'
import { Parameters } from 'saas/page/statistics/Parameters'
import { Totals } from 'saas/page/statistics/total/Totals'

export const StatisticsView = observer(() => {
  return <Vertical>
    <PageHeader title={i18n('title.Dashboard')} />
    <Vertical wide gap={16}>
      <Typo size={18} semi>
        {i18n('statistics.Settings')}
      </Typo>
      <Vertical wide>
        <Parameters />
      </Vertical>
    </Vertical>
    <Gap height={24} />
    <Divider secondary />
    <Gap height={24} />
    <Vertical wide gap={16}>
      <Typo size={18} semi>
        {i18n('statistics.GeneralIndications')}
      </Typo>
      <Vertical gap={16}>
        <Totals />
        <ChartView />
      </Vertical>
    </Vertical>
    <Gap height={16} />
    <TopProducts />
  </Vertical>
})
