// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tooltip-card-ec6f{background-color:var(--ui-text-color-base);color:var(--ui-background-color);z-index:999999}`, "",{"version":3,"sources":["webpack://./src/saas/page/statistics/chart/Tooltip.module.scss"],"names":[],"mappings":"AAAA,mBACE,0CAAA,CACA,gCAAA,CACA,cAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `Tooltip-card-ec6f`
};
module.exports = ___CSS_LOADER_EXPORT___;
