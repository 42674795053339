import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import css from './CompanySection.module.scss'

type Props = {
  title?: string
  logoUrl?: string
}

export const CompanySection = ({ title, logoUrl }: Props) => {
  return <Space wide pad={8} gap={8} className={css.company}>
    <div className={css.icon}>
      {logoUrl ? <img src={logoUrl} alt="logo" /> : ''}
    </div>
    <Vertical>
      <Typo size={11}>{i18n('label.Company')}</Typo>
      <Typo size={13}>{title}</Typo>
    </Vertical>
  </Space>
}
