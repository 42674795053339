import { clsx } from 'clsx'
import { Space } from 'component/Space'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { observer } from 'mobx-react-lite'
import { PartialOptions } from 'overlayscrollbars'
import { useOverlayScrollbars } from 'overlayscrollbars-react'
import { routesStore } from 'saas/route/RoutesStore'
import { Logo } from 'saas/screen/logo/Logo'
import { CompanyItem } from 'saas/screen/sidebar/CompanyItem'
import { ExpandItem } from 'saas/screen/sidebar/ExpandItem'
import { renderRouteItems } from 'saas/screen/sidebar/RouteItem'
import { UserItem } from 'saas/screen/sidebar/UserItem'
import { saas } from 'saas/store/SaasController'
import { sidebar } from 'saas/store/SidebarStore'
import { home } from 'store/home'
import css from './Sidebar.module.scss'

export const Sidebar = observer(() => {
  const { open } = sidebar
  const ready = saas.ready && home.company.present
  const className = clsx('os-transitionend', css.sidebar, css.open, !open && css.toggled)

  const options: PartialOptions = {
    overflow: { x: 'hidden', y: 'scroll' },
    scrollbars: { theme: 'os-theme-dark os-contrast' },
  }
  const [initialize, _instance] = useOverlayScrollbars({ defer: true, options })
  const onRef = useLatestCallback((element: HTMLDivElement | null) => {
    if (element) initialize(element)
  })

  return <div className={className}>
    <div className={css.fixed}>
      <div className={css.over}>
        <div ref={onRef} className={css.content}>
          <Vertical className={css.inner} gap={12} pad={[24, 0]}>
            <Space center>
              <Logo />
            </Space>
            <Vertical className={clsx(css.menu, ready && css.ready)} pad={[0, 24]} opposite tall>
              <Vertical gap={16}>
                <CompanyItem />
                <Vertical>
                  {renderRouteItems(routesStore.routes)}
                </Vertical>
              </Vertical>
              <Vertical gap={8}>
                <UserItem />
                <ExpandItem />
              </Vertical>
            </Vertical>
          </Vertical>
        </div>
      </div>
    </div>
  </div>
})
