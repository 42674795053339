import { OverlayScrollbarsComponent, OverlayScrollbarsComponentRef } from 'overlayscrollbars-react'
import { forwardRef, ReactNode } from 'react'

type Props = {
  children: ReactNode
  className?: string
}

export type ScrollRef = OverlayScrollbarsComponentRef

export const Scroll = forwardRef<ScrollRef, Props>(({ children, className }, ref) => {
  return <OverlayScrollbarsComponent ref={ref} className={className} options={{ scrollbars: { theme: '' } }}>
    {children}
  </OverlayScrollbarsComponent>
})
