// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TextArea-area-4e9f{background:var(--ui-input-background);border:1px solid var(--ui-input-background)}.TextArea-area-4e9f:focus,.TextArea-area-4e9f:hover{background:var(--ui-input-background)}.TextArea-area-4e9f:read-only{color:var(--ui-text-color-secondary)}.TextArea-fix-4e9f{resize:none !important}.TextArea-mono-4e9f{font-family:var(--ui-font-family-monospace),monospace;letter-spacing:-0.5px;font-size:12px;white-space:pre}`, "",{"version":3,"sources":["webpack://./src/component/TextArea/TextArea.module.scss"],"names":[],"mappings":"AAAA,oBACE,qCAAA,CACA,2CAAA,CAEA,oDACE,qCAAA,CAIJ,8BACE,oCAAA,CAGF,mBACE,sBAAA,CAGF,oBACE,qDAAA,CACA,qBAAA,CACA,cAAA,CACA,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"area": `TextArea-area-4e9f`,
	"fix": `TextArea-fix-4e9f`,
	"mono": `TextArea-mono-4e9f`
};
module.exports = ___CSS_LOADER_EXPORT___;
