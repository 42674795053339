import IconFileUpload from 'asset/icons/icon-fileupload.svg'
import { IconButton } from 'component/Button/IconButton'
import { Sticker } from 'component/Glass'
import { Image, Preview } from 'component/Image'
import { usePreviewControl } from 'component/Image/PreviewControl'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Upload } from 'component/Upload'
import { Vertical } from 'component/Vertical'
import { FieldStore } from 'form/store/FieldStore'
import { useLatestCallback } from 'hook/useLatestCallback'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { ImageFile } from 'store/base/ImageFile'
import { nil } from 'util/null'
import css from './ImageUpload.module.scss'

type Props = {
  field: FieldStore<ImageFile | undefined>
  title: string
  description?: string
  original?: string | nil
  disabled?: boolean
}

export const ImageUpload = observer(({ field, title, original, disabled, description }: Props) => {
  const source = field.value?.url
  const sources = source ? [source] : []
  const reset = (original ?? '') !== (source ?? '')
  const control = usePreviewControl()

  const onFile = useLatestCallback((file: File) => {
    field.value = ImageFile.fromFile(file)
  })

  const onPreview = useLatestCallback(() => {
    control.setPreview(source)
  })

  const onDelete = useLatestCallback(() => {
    field.value = undefined
  })

  const onReset = useLatestCallback(() => {
    field.value = ImageFile.fromUrlNil(original)
  })

  return <Space className={css.wrapper}>
    <Preview control={control} sources={sources} />
    <Upload disabled={disabled} accept="image" width={120} height={120} onFile={onFile}>
      {!source && <Vertical gap={8} center>
        <div className={css.iconUpload}>
          <IconFileUpload width={24} />
        </div>
        <Vertical>
          <Typo secondary>{title}</Typo>
          {description && <Typo secondary>{description}</Typo>}
        </Vertical>
      </Vertical>}
      {source && <Image src={source} />}
    </Upload>
    {source && <Sticker pin={[null, null, 4, 4]}>
      <IconButton small action={onPreview}>
        <Icon name="fullscreen" size={16} />
      </IconButton>
    </Sticker>}
    {source && <Sticker pin={[4, 4, null, null]}>
      <IconButton small action={onDelete}>
        <Icon name="close" size={16} />
      </IconButton>
    </Sticker>}
    {reset && <Sticker pin={[null, 4, 4, null]}>
      <IconButton small action={onReset}>
        <Icon name="refresh" size={16} />
      </IconButton>
    </Sticker>}
  </Space>
})
