// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ImageUpload-wrapper-5df7{background:rgba(82,123,244,.04);padding:8px;border-radius:12px}.ImageUpload-wrapper-5df7 .ant-btn-dashed{background:rgba(0,0,0,0);border-color:#527bf4}.ImageUpload-iconUpload-5df7{width:40px;height:40px;border-radius:50%;background:rgba(82,123,244,.08);display:flex;align-items:center;justify-content:center}`, "",{"version":3,"sources":["webpack://./src/saas/page/company/common/ImageUpload.module.scss"],"names":[],"mappings":"AAAA,0BACE,+BAAA,CACA,WAAA,CACA,kBAAA,CAEA,0CACE,wBAAA,CACA,oBAAA,CAIJ,6BACE,UAAA,CACA,WAAA,CACA,iBAAA,CACA,+BAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `ImageUpload-wrapper-5df7`,
	"iconUpload": `ImageUpload-iconUpload-5df7`
};
module.exports = ___CSS_LOADER_EXPORT___;
