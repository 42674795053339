// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProductView-view-105e{margin-top:16px}.ProductView-paper-105e,.ProductView-product-105e,.ProductView-box-105e{flex:1 1 0;min-width:320px;max-width:480px}@media(width <= 720px){.ProductView-product-105e{margin-bottom:100vh}.ProductView-box-105e{z-index:1;position:sticky;bottom:24px;left:24px;width:100%;top:0;max-width:min(480px,100vw - 48px)}}@media(width <= 480px){.ProductView-view-105e,.ProductView-paper-105e,.ProductView-product-105e,.ProductView-box-105e{flex:0 0 auto;width:100%;min-width:auto;max-width:calc(100vw - 48px)}}`, "",{"version":3,"sources":["webpack://./src/page/internal/product/ProductView.module.scss"],"names":[],"mappings":"AAAA,uBACE,eAAA,CAGF,wEACE,UAAA,CACA,eAAA,CACA,eAAA,CAGF,uBACE,0BACE,mBAAA,CAGF,sBACE,SAAA,CACA,eAAA,CACA,WAAA,CACA,SAAA,CACA,UAAA,CACA,KAAA,CACA,iCAAA,CAAA,CAIJ,uBACE,+FACE,aAAA,CACA,UAAA,CACA,cAAA,CACA,4BAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"view": `ProductView-view-105e`,
	"paper": `ProductView-paper-105e`,
	"product": `ProductView-product-105e`,
	"box": `ProductView-box-105e`
};
module.exports = ___CSS_LOADER_EXPORT___;
