import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { ReactNode } from 'react'

type Props = {
  title: string
  children: ReactNode | ReactNode[]
}

export const FormLabel = ({ title, children }: Props) => {
  return <Vertical gap={8}>
    <Typo size={13}>{title}</Typo>
    <Space>{children}</Space>
  </Vertical>
}
