import { development } from 'app/config/constants'
import { security } from 'app/controller/security'
import IconInvite from 'asset/icons/icon-invite.svg'
import IconLogout from 'asset/icons/icon-logout.svg'
import IconProfile from 'asset/icons/icon-profile.svg'
import { DropdownItem } from 'component/Popover/Dropdown'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { modal } from 'modal'
import { SideSelect } from 'saas/screen/sidebar/SideSelect'
import { can } from 'store/can'
import { home } from 'store/home'
import { me } from 'store/me'
import { compact } from 'util/array'

export const UserItem = observer(() => {

  const company = home.company.optional
  const canInvite = can.InviteUsers(company?.company_id)
  const image = me.user.picture
  const text = me.user.name

  const items: DropdownItem[] = compact([
    {
      key: 'profile',
      label: i18n('common.Profile'),
      action: () => modal.openProfileModal(),
      icon: <IconProfile />,
    },
    development && {
      key: 'support',
      label: i18n('common.Support'),
      action: () => modal.openSupportModal(),
      icon: <div />,
    },
    canInvite && { divider: true },
    canInvite && {
      key: 'invite',
      label: i18n('invite.Invite'),
      action: () => {
        const company = home.company.optional
        modal.openInviteUsersModal(company)
      },
      icon: <IconInvite />,
    },
    { divider: true },
    {
      key: 'logout',
      label: i18n('label.Logout'),
      action: () => security.logout(),
      icon: <IconLogout />,
    },
  ])

  return <SideSelect image={image} text={text} items={items} placement="topLeft" />
})
