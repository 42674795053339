import { Button } from 'component/Button'
import { IconButton } from 'component/Button/IconButton'
import { Card } from 'component/Card'
import { Link } from 'component/Link'
import { PageHeader } from 'component/PageHeader'
import { Dropdown, DropdownItem } from 'component/Popover/Dropdown'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { modal } from 'modal'
import { ExternalStatusSelect } from 'page/internal/status/ExternalStatusSelect'
import { InternalStatusSelect } from 'page/internal/status/InternalStatusSelect'
import { can } from 'store/can'
import { home } from 'store/home'
import { compact } from 'util/array'
import { formatDate, formatDateTime, parseDate } from 'util/date'
import { emdash, space } from 'util/typo'

export const ProductTitle = observer(() => {
  const product = home.product.it
  const { company_id, product_name, created_at, updated_at, links } = product.data.json

  const created = parseDate(created_at)
  const updated = parseDate(updated_at)

  const canAdd = can.AddAndEditProducts(company_id)
  const canDelete = can.DeleteProducts(company_id)
  const more = canAdd || canDelete

  const items: DropdownItem[] = compact([
    canDelete && {
      key: 'delete',
      label: i18n('item.DeleteItem'),
      async action() {
        const item = home.collection.optional?.items.optional?.find(item => item.store.optional === product)
        if (!item) return

        if (modal.openDeleteProductModal(item)) return
        await home.deleteProduct(item)
      },
    },
  ])

  const onTest = useLatestCallback(async () => {
    await modal.openTestItemModal(product)
  })

  const onBack = useLatestCallback(() => {
    home.closeProduct()
  })

  return <>
    <PageHeader title={product_name} onBack={onBack}>
      {more && <Space gap={8} height={24}>
        {canAdd && <Button primary action={onTest}>
          {i18n('item.TestItem')}
        </Button>}
        <Dropdown items={items} placement={'bottomRight'}>
          <IconButton>
            <Icon name="more_horiz" />
          </IconButton>
        </Dropdown>
      </Space>}
    </PageHeader>

    <Card gap={12} pad={16} vertical>
      <Space gap={16} top opposite>
        <Space>
          <Typo size={18} bold wrap>
            {product_name}
          </Typo>
        </Space>
      </Space>

      <Space gap={[8, 12]} wrap>
        <Space width={140}>
          <InternalStatusSelect product={product} />
        </Space>
        <Space width={140}>
          <ExternalStatusSelect product={product} />
        </Space>
      </Space>

      <Space gap={[8, 16]} wrap>
        <Space gap={4} title={formatDateTime(created)}>
          <Icon size={14} name="calendar_today" secondary />
          <Typo size={12} secondary>
            {i18n('label.CreatedAt')}
          </Typo>
          {space}
          <Typo size={12}>{formatDate(created) ?? emdash}</Typo>
        </Space>
        <Space gap={4} title={formatDateTime(updated)}>
          <Icon size={14} name="calendar_today" secondary />
          <Typo size={12} secondary>
            {i18n('label.LastUpdate')}
          </Typo>
          {space}
          <Typo size={12}>{formatDate(updated) ?? emdash}</Typo>
        </Space>
        {links && <Space gap={4} maxWidth={400} wide>
          <Icon size={14} name="link" secondary />
          {space}
          <Link small href={links}>
            {links}
          </Link>
        </Space>}
      </Space>
    </Card>
  </>
})
