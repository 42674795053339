import { Card } from 'component/Card'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { IntervalSelect } from 'saas/page/statistics/IntervalSelect'
import { FormLabel } from './FormLabel'

export const Parameters = observer(() => {
  return <Card gap={16} pad={16} vertical wide>
    <Vertical gap={16}>
      {/*<FormLabel title={i18n('statistics.ChooseProduct')}>*/}
      {/*  <ChooseProduct />*/}
      {/*</FormLabel>*/}
      {/*<FormLabel title={i18n('statistics.ChooseProductGroup')}>*/}
      {/*  <ChooseProductGroup />*/}
      {/*</FormLabel>*/}
      <FormLabel title={i18n('statistics.ChoosePeriod')}>
        <IntervalSelect />
      </FormLabel>
    </Vertical>
  </Card>
})
