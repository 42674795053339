import IconSidebarToggle from 'asset/icons/icon-sidebar-toggle.svg'
import { Tooltip } from 'component/Popover/Tooltip'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { sidebar } from 'saas/store/SidebarStore'
import css from './ExpandItem.module.scss'

export const ExpandItem = observer(() => {
  const { open } = sidebar
  const text = i18n('sidebar.CompactAppearance')
  const tooltip = open ? undefined : text

  const onOpen = useLatestCallback(() => {
    sidebar.open = !sidebar.open
  })

  return <Tooltip title={tooltip} right>
    <button className={css.buttonView} onClick={onOpen}>
      <div className={css.buttonViewIcon}>
        <IconSidebarToggle width={16} />
      </div>
      {open && <div className={css.buttonViewTitle}>
        {text}
      </div>}
    </button>
  </Tooltip>
})
