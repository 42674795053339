// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tooltip-tooltip-ffdd .ant-tooltip-inner{background-color:var(--ui-text-color-base);opacity:1;padding:8px 12px !important;font-size:13px}.Tooltip-child-ffdd{cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/component/Popover/Tooltip.module.scss"],"names":[],"mappings":"AACE,yCACE,0CAAA,CACA,SAAA,CACA,2BAAA,CACA,cAAA,CAIJ,oBACE,cAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": `Tooltip-tooltip-ffdd`,
	"child": `Tooltip-child-ffdd`
};
module.exports = ___CSS_LOADER_EXPORT___;
