import { clsx } from 'clsx'
import { Card } from 'component/Card'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { ReactElement } from 'react'
import { Chart } from 'saas/page/statistics/chart/Chart'
import { chart } from 'saas/store/stat/ChartStore'
import css from './ChartView.module.scss'

function renderContent(): ReactElement | undefined {
  const { error, ready, series } = chart
  if (error) return <Typo size={12} error>
    {i18n('common.Error')}
  </Typo>
  if (!ready) return <Typo size={12} secondary>
    {i18n('chart.Loading')}
  </Typo>
  if (!series.length) return <Typo size={12} secondary>
    {i18n('chart.NoData')}
  </Typo>
  return undefined
}

export const ChartView = observer(() => {
  const { ready } = chart
  const hide = !ready
  const className = clsx(css.view, hide && css.hide)
  const content = renderContent()

  return <Card className={css.card} pad={[16, 0]}>
    <Space className={className} center={!!content}>
      {content || <Chart />}
    </Space>
  </Card>
})
