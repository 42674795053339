import { IconButton } from 'component/Button/IconButton'
import { Space } from 'component/Space'
import { Spinner } from 'component/Spinner'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { CommentEditor } from 'page/internal/comments/CommentEditor'
import { CommentsList } from 'page/internal/comments/CommentsList'
import { CommentTitle } from 'page/internal/comments/CommentTitle'
import { commenting } from 'store/comment/CommentingStore'
import css from './CommentsSection.module.scss'

type Props = {
  className?: string
}

export const CommentsSection = observer(({ className }: Props) => {
  const store = commenting.comments
  const { ready } = store

  const onClose = useLatestCallback(() => {
    commenting.close()
  })

  return <Vertical className={className}>
    <Space className={css.title} gap={16} pad={[8, 16]} opposite>
      <CommentTitle />
      <IconButton small action={onClose}>
        <Icon name="close" />
      </IconButton>
    </Space>
    <Vertical className={css.main}>
      {!ready && <Space height={200} center>
        <Spinner />
      </Space>}
      {ready && <CommentsList />}
    </Vertical>
    <CommentEditor />
  </Vertical>
})
