// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SideSelect-item-87b3{--card-border-radius: 12px;--card-border-color: rgb(250, 250, 250, 0.16);padding:0 8px;background-color:var(--sidebar-background);color:rgba(250,250,250,.64)}.SideSelect-item-87b3:hover{background-color:var(--ui-background-color);color:rgba(30,30,30,.88)}.SideSelect-logo-87b3{flex-shrink:0;width:32px;height:32px;background:rgba(250,250,250,.08);border-radius:6px;overflow:hidden}.SideSelect-logo-87b3 img{display:block;max-width:100%;object-fit:contain}.SideSelect-item-87b3:hover .SideSelect-logo-87b3{background:rgba(0,0,0,.08)}.SideSelect-compact-87b3 .SideSelect-text-87b3{display:none}.SideSelect-icon-87b3{color:rgba(250,250,250,.32);transition:color 200ms ease-in-out}.SideSelect-item-87b3:hover .SideSelect-icon-87b3{color:rgba(30,30,30,.88)}.SideSelect-compact-87b3 .SideSelect-icon-87b3{display:none}`, "",{"version":3,"sources":["webpack://./src/saas/screen/sidebar/SideSelect.module.scss"],"names":[],"mappings":"AAAA,sBACE,0BAAA,CACA,6CAAA,CAEA,aAAA,CACA,0CAAA,CACA,2BAAA,CAGF,4BACE,2CAAA,CACA,wBAAA,CAGF,sBACE,aAAA,CACA,UAAA,CACA,WAAA,CACA,gCAAA,CACA,iBAAA,CACA,eAAA,CAEA,0BACE,aAAA,CACA,cAAA,CACA,kBAAA,CAIJ,kDACE,0BAAA,CAGF,+CACE,YAAA,CAGF,sBACE,2BAAA,CACA,kCAAA,CAGF,kDACE,wBAAA,CAGF,+CACE,YAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `SideSelect-item-87b3`,
	"logo": `SideSelect-logo-87b3`,
	"compact": `SideSelect-compact-87b3`,
	"text": `SideSelect-text-87b3`,
	"icon": `SideSelect-icon-87b3`
};
module.exports = ___CSS_LOADER_EXPORT___;
