// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CommentEditor-editor-6edd{border-top:1px solid var(--ui-border-color-secondary);background-color:var(--ui-background-color)}.CommentEditor-inline-6edd{border:1px solid var(--ui-border-color-secondary);border-radius:var(--ui-border-radius)}.CommentEditor-editor-6edd:focus-within{border-color:var(--ui-primary-color)}.CommentEditor-scroll-6edd{max-height:128px;padding:4px 8px}.CommentEditor-input-6edd{resize:none !important;border:none;box-shadow:none !important;background-color:rgba(0,0,0,0) !important;overflow:hidden !important}.CommentEditor-send-6edd{flex:0 0 auto}`, "",{"version":3,"sources":["webpack://./src/page/internal/comments/CommentEditor.module.scss"],"names":[],"mappings":"AAAA,2BACE,qDAAA,CACA,2CAAA,CAGF,2BACE,iDAAA,CACA,qCAAA,CAGF,wCACE,oCAAA,CAGF,2BACE,gBAAA,CACA,eAAA,CAGF,0BACE,sBAAA,CACA,WAAA,CACA,0BAAA,CACA,yCAAA,CACA,0BAAA,CAIF,yBACE,aAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editor": `CommentEditor-editor-6edd`,
	"inline": `CommentEditor-inline-6edd`,
	"scroll": `CommentEditor-scroll-6edd`,
	"input": `CommentEditor-input-6edd`,
	"send": `CommentEditor-send-6edd`
};
module.exports = ___CSS_LOADER_EXPORT___;
