import { Button as ButtonAntd } from 'antd'
import { clsx } from 'clsx'
import { useFocus } from 'hook/useFocus'
import { useLatestCallback } from 'hook/useLatestCallback'
import { ClickAction, useOnClickAction } from 'hook/useOnClickAction'
import { Icon } from 'icon/Icon'
import { CSSProperties, MouseEvent, ReactNode } from 'react'
import { getPaddingStyle, Padding, styles } from 'util/style'
import css from './Button.module.scss'

type ButtonType = 'default' | 'primary' | 'dashed' | 'link' | 'text'

export type ButtonProps = {
  action?: ClickAction | false
  active?: boolean
  children?: ReactNode
  className?: string
  compact?: boolean
  dashed?: boolean
  disabled?: boolean
  error?: boolean
  fit?: boolean
  height?: number | string
  icon?: ReactNode
  large?: boolean
  left?: boolean
  link?: boolean
  onClick?(event: MouseEvent): void
  onFocus?(focus: boolean): void
  onMouseDown?(event: MouseEvent): void
  text?: boolean
  pad?: Padding
  primary?: boolean
  sharp?: boolean
  small?: boolean
  style?: CSSProperties
  title?: string
  type?: ButtonType
  wide?: boolean
  width?: number | string
  filled?: boolean
}

export function Button(props: ButtonProps) {
  const {
    active, children, className, compact, dashed, disabled, error,
    filled,
    fit, height, icon, large, left, link, text, primary, sharp, small, title, wide, width,
  } = props
  const { busy, onClick } = useOnClickAction(props)
  const { onFocus, onBlur } = useFocus(props)

  const type = props.type ? props.type : primary ? 'primary' : dashed ? 'dashed' :
    text ? 'text' : link ? 'link' : undefined
  const size = large ? 'large' : small ? 'small' : undefined
  const classes = clsx(className, css.button, busy && 'ant-btn-loading',
    active && css.active, compact && css.compact, left && css.left, link && css.link,
    filled && css.filled, text && css.text,
    wide && css.wide, fit && css.fit, sharp && css.sharp, small && css.small, large && css.large)
  const pad = getPaddingStyle(props.pad)
  const style = styles({ width, height, ...pad, ...props.style })
  const _icon = !icon ? null : busy ? <Icon name="progress_activity" /> : icon

  const onMouseDown = useLatestCallback((event: MouseEvent) => {
    props.onMouseDown?.(event)
  })

  return <ButtonAntd type={type} size={size} icon={_icon} disabled={disabled} danger={error}
    className={classes} style={style} onClick={onClick} onMouseDown={onMouseDown}
    title={title} onFocus={onFocus} onBlur={onBlur}>
    <span className={css.span}>
      {children}
    </span>
  </ButtonAntd>
}
