// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DateTick-tick-b6f1{font-family:inherit;font-size:13px}.DateTick-date-b6f1{fill:var(--ui-text-color)}.DateTick-year-b6f1{fill:var(--ui-text-color-secondary)}`, "",{"version":3,"sources":["webpack://./src/saas/page/statistics/chart/DateTick.module.scss"],"names":[],"mappings":"AAAA,oBACE,mBAAA,CACA,cAAA,CAGF,oBACE,yBAAA,CAGF,oBACE,mCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tick": `DateTick-tick-b6f1`,
	"date": `DateTick-date-b6f1`,
	"year": `DateTick-year-b6f1`
};
module.exports = ___CSS_LOADER_EXPORT___;
