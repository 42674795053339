// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CompanySection-company-f4ca{border-radius:12px;border:1px solid rgba(30,30,30,.08)}.CompanySection-icon-f4ca{width:32px;min-width:32px;height:32px;border-radius:6px;overflow:hidden;border:1px solid rgba(30,30,30,.04)}.CompanySection-icon-f4ca img{display:block;max-width:100%;object-fit:contain}`, "",{"version":3,"sources":["webpack://./src/page/invite/CompanySection.module.scss"],"names":[],"mappings":"AAAA,6BACE,kBAAA,CACA,mCAAA,CAGF,0BACE,UAAA,CACA,cAAA,CACA,WAAA,CACA,iBAAA,CACA,eAAA,CACA,mCAAA,CAEA,8BACE,aAAA,CACA,cAAA,CACA,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"company": `CompanySection-company-f4ca`,
	"icon": `CompanySection-icon-f4ca`
};
module.exports = ___CSS_LOADER_EXPORT___;
