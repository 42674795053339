import { Divider } from 'antd'
import { Button } from 'component/Button'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { TextField } from 'form/TextField'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { ImageUpload } from 'saas/page/company/common/ImageUpload'
import { companyEdit } from 'saas/store/CompanyEditStore'
import { can } from 'store/can'

export const DataSection = observer(() => {
  const { company_id, company, name, description, link, logo, canSave } = companyEdit.it
  const canEdit = can.EditCompanyInformation(company_id)

  const onSave = useLatestCallback(async () => {
    await companyEdit.it.save()
  })

  return <Vertical gap={16}>
    <Space gap={24} wide top>
      <Vertical gap={16} wide>
        <Typo size={16} semi>
          {i18n('company.About')}
        </Typo>
        <Space gap={16} top wide>
          <Vertical gap={12} wide>
            <Space gap={12} wide>
              <TextField field={name} label={i18n('label.Name')} disabled={!canEdit} />
              <TextField field={link} label={i18n('label.Website')} disabled={!canEdit} />
            </Space>
            <TextField rows={4} field={description} label={i18n('label.Description')} disabled={!canEdit} />
          </Vertical>
        </Space>
      </Vertical>
      <Vertical gap={16}>
        <Typo size={16} semi>
          {i18n('company.CompanyLogo')}
        </Typo>
        <Space gap={16} top>
          <ImageUpload field={logo} disabled={!canEdit} original={company.json.logo_url}
            title={i18n('label.Logo')} description={i18n('label.LogoDescription')} />
        </Space>
      </Vertical>
    </Space>
    <Divider style={{ margin: 0 }} />
    <Space opposite>
      {canEdit && <Button primary disabled={!canSave} action={onSave}>
        {i18n('common.Save')}
      </Button>}
    </Space>
  </Vertical>
})
