import dayjs, { Dayjs } from 'dayjs'
import { makeAutoObservable } from 'mobx'
import { nil } from 'util/null'

function today() {
  return dayjs().startOf('day')
}

function week() {
  return today().subtract(1, 'week')
}

function month() {
  return today().subtract(1, 'month')
}

function quarter() {
  return today().subtract(3, 'month')
}

export class IntervalStore {
  private _start: Dayjs = week()
  private _end: Dayjs = today()

  constructor() {
    makeAutoObservable(this)
  }

  get start(): Dayjs {
    return this._start
  }

  set start(value: Dayjs | nil) {
    const start = value ?? today()
    if (start.isSame(this.start)) return
    this._start = start
  }

  get end(): Dayjs {
    return this._end ?? today()
  }

  set end(value: Dayjs | nil) {
    const end = value ?? today()
    if (end.isSame(this.end)) return
    this._end = value ?? end
  }

  get value(): [Dayjs, Dayjs] {
    return [this.start, this.end]
  }

  get today(): boolean {
    return today().isSame(this.start)
      && today().isSame(this.end)
  }

  set today(_: true) {
    this.start = today()
    this.end = today()
  }

  get week(): boolean {
    return week().isSame(this.start)
      && today().isSame(this.end)
  }

  set week(_: true) {
    this.start = week()
    this.end = today()
  }

  get month(): boolean {
    return month().isSame(this.start)
      && today().isSame(this.end)
  }

  set month(_: true) {
    this.start = month()
    this.end = today()
  }

  get quarter(): boolean {
    return quarter().isSame(this.start)
      && today().isSame(this.end)
  }

  set quarter(_: true) {
    this.start = quarter()
    this.end = today()
  }
}
