import IconAdd from 'asset/icons/icon-add.svg'
import { Button } from 'component/Button'
import { Card } from 'component/Card'
import { Input } from 'component/Input'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { modal } from 'modal'
import { MemberRow } from 'saas/page/company/manage/MemberRow'
import { companyEdit } from 'saas/store/CompanyEditStore'
import { can } from 'store/can'
import { home } from 'store/home'
import css from './MemberSection.module.scss'

export const MemberSection = observer(() => {
  const { company, memberFilter, filteredMembers } = companyEdit.it
  const co = home.companies.findCompany(company.company_id)

  const onChangeFilter = useLatestCallback((value: string) => {
    companyEdit.it.memberFilter = value
  })

  const onInvite = useLatestCallback(async () => {
    if (co) modal.openInviteUsersModal(co)
  })

  const empty = !filteredMembers.length
  return <Card pad={24} gap={16} vertical wide>
    <Space gap={16} opposite>
      <Space gap={24}>
        <Typo size={16} bold>
          {i18n('member.Members')}
        </Typo>
      </Space>
      <Space gap={10}>
        <Input value={memberFilter} onChange={onChangeFilter} placeholder={i18n('placeholder.Search')} />
        <Space>
          {can.InviteUsers(company.company_id) && <Button primary icon={<IconAdd width={16} />} action={onInvite}>
            {i18n('invite.Invite')}
          </Button>}
        </Space>
      </Space>
    </Space>
    {empty && <Typo size={12} secondary>
      {i18n('member.NoMembers')}
    </Typo>}
    {!empty && <table className={css.table}>
      <thead>
      <tr>
        <td>{i18n('member.User')}</td>
        <td>{i18n('member.InvitationDate')}</td>
        <td>{i18n('member.SignUpDate')}</td>
        <td>{i18n('member.LastSignIn')}</td>
        <td>{i18n('member.Roles')}</td>
      </tr>
      </thead>
      <tbody>
      {filteredMembers.map(m => <MemberRow key={m.user_id} member={m} />)}
      </tbody>
    </table>}
  </Card>
})
