import { clsx } from 'clsx'
import { Card } from 'component/Card'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { ItemRow } from 'saas/page/statistics/items/ItemRow'
import { tops } from 'saas/store/stat/TopItemsStore'
import css from './TopProducts.module.scss'

export const TopProducts = observer(() => {
  const { ready, items } = tops
  const hide = !ready
  const empty = !items.length

  return <Card gap={16} pad={16} vertical>
    <Typo size={15} semi>
      {i18n('statistics.TopTenProducts')}
    </Typo>
    <div className={clsx(css.view, hide && css.hide)}>
      {!ready && <Space height={200} center>
        <Typo size={12} secondary>
          {i18n('statistics.Loading')}
        </Typo>
      </Space>}
      {ready && empty && <Space height={200} center>
        <Typo size={12} secondary>
          {i18n('statistics.NoItems')}
        </Typo>
      </Space>}
      {ready && !empty && <table className={css.table}>
        <thead>
        <tr>
          <td>{i18n('statistics.Product')}</td>
          <td>{i18n('statistics.Views')}</td>
          <td>{i18n('statistics.Generations')}</td>
          <td>{i18n('statistics.Conversion')}</td>
          <td>{i18n('statistics.Clicks')}</td>
        </tr>
        </thead>
        <tbody>
        {items.map(item => <ItemRow key={item.id} item={item} />)}
        </tbody>
      </table>}
    </div>
  </Card>
})
