import { Card } from 'component/Card'
import { Image } from 'component/Image'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { TopItem } from 'type/Statistics'
import { emdash } from 'util/typo'

type ItemRowProps = {
  item: TopItem
}

export const ItemRow = ({ item }: ItemRowProps) => {
  const { brand, image, name } = item
  const views = item.views ?? 0
  const gen = item.generations ?? 0
  const opens = item.opens ?? 0
  const conversion = views > 0 ? (gen / views) * 100 : 0

  return <tr>
    <td>
      <Space gap={12}>
        <Card height={72} small pic>
          {image && <Image src={image} />}
        </Card>
        <Vertical gap={4}>
          <Typo size={13} medium>{name ?? emdash}</Typo>
          <Typo size={13} secondary>{brand ?? emdash}</Typo>
        </Vertical>
      </Space>
    </td>
    <td>
      <Typo size={13} medium>{views.toFixed()}</Typo>
    </td>
    <td>
      <Typo size={13} medium>{gen.toFixed()}</Typo>
    </td>
    <td>
      <Typo size={13} medium>{conversion.toFixed()}%</Typo>
    </td>
    <td>
      <Typo size={13} medium>{opens.toFixed()}</Typo>
    </td>
  </tr>
}
