import { Button } from 'component/Button'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { inviteUsers } from 'page/invite/store/InviteUsersStore'
import { CompanySection } from './CompanySection'

export const FinalStep = observer(() => {
  const { company, users, onBack, canInvite, onInvite } = inviteUsers.it
  const title = company?.title
  const logo = company?.company.logo_url

  return <Vertical gap={24}>
    <CompanySection title={title} logoUrl={logo} />

    <Typo size={12}>
      {i18n('invite.SendInvitationsToCountUsers', { count: users.length })}
    </Typo>

    <Space gap={12}>
      <Button wide action={onBack}>
        {i18n('common.GoBack')}
      </Button>
      <Button wide primary disabled={!canInvite} action={onInvite}>
        {i18n('invite.SendInvites')}
      </Button>
    </Space>
  </Vertical>
})
