// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IntervalSelect-input-1949{--ant-line-width: 0;background:rgba(0,0,0,0)}.IntervalSelect-input-1949 .ant-picker-input{width:90px;height:24px}.IntervalSelect-input-1949 .ant-picker-active-bar{bottom:0}.IntervalSelect-dropdown-1949{--ant-font-size: 12px;--ant-font-weight-strong: 500}.IntervalSelect-dropdown-1949 .ant-picker-date-panel{--ant-date-picker-cell-width: 32px;--ant-date-picker-cell-height: 24px;--ant-padding: 0px;--ant-padding-xxs: 4px / 1.5;--ant-date-picker-cell-active-with-range-bg: var(--ui-background-color-soft)}.IntervalSelect-dropdown-1949 .ant-picker-cell-range-start:not(.ant-picker-cell-disabled) .ant-picker-cell-inner,.IntervalSelect-dropdown-1949 .ant-picker-cell-range-end:not(.ant-picker-cell-disabled) .ant-picker-cell-inner{color:var(--ui-text-color) !important;background:var(--ui-background-color-hover) !important}.IntervalSelect-dropdown-1949 .ant-picker-cell-today .ant-picker-cell-inner{color:var(--ui-primary-color)}.IntervalSelect-dropdown-1949 .ant-picker-cell-today .ant-picker-cell-inner::before{content:none !important}.IntervalSelect-wrapper-1949{background:#f5f5f5;border-radius:8px}.IntervalSelect-button-1949.IntervalSelect-button-1949{min-height:36px;height:36px;padding:0 16px}`, "",{"version":3,"sources":["webpack://./src/saas/page/statistics/IntervalSelect.module.scss"],"names":[],"mappings":"AAAA,2BACE,mBAAA,CACA,wBAAA,CAGE,6CACE,UAAA,CACA,WAAA,CAGF,kDACE,QAAA,CAKN,8BACE,qBAAA,CACA,6BAAA,CAGE,qDACE,kCAAA,CACA,mCAAA,CACA,kBAAA,CACA,4BAAA,CACA,4EAAA,CAGF,gOAEE,qCAAA,CACA,sDAAA,CAGF,4EACE,6BAAA,CAGF,oFACE,uBAAA,CAKN,6BACE,kBAAA,CACA,iBAAA,CAGF,uDACE,eAAA,CACA,WAAA,CACA,cAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `IntervalSelect-input-1949`,
	"dropdown": `IntervalSelect-dropdown-1949`,
	"wrapper": `IntervalSelect-wrapper-1949`,
	"button": `IntervalSelect-button-1949`
};
module.exports = ___CSS_LOADER_EXPORT___;
