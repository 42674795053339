import { makeAutoObservable, observable, runInAction } from 'mobx'
import { ChartSerie, createSeries, SerieGroup } from 'saas/store/stat/ChartSerie'
import { api } from 'store/api'
import { StatRequest } from 'type/Statistics'
import { skipAsync } from 'util/async'

export class ChartStore {
  ready = false
  error = false
  series: ChartSerie[] = []
  group: SerieGroup = 'day'

  constructor() {
    makeAutoObservable(this, { series: observable.ref })
  }

  reset() {
    this.ready = false
    this.error = false
    this.series = []
  }

  readonly update = skipAsync(async (request: StatRequest) => {
    this.reset()
    try {
      const response = await api.getSeries(request)
      const { series, group } = createSeries(response)
      runInAction(() => {
        this.series = series
        this.group = group
        this.ready = true
      })
    } catch (e) {
      console.error('load failed', e)
      runInAction(() => this.error = true)
    }
  })

}

export const chart = new ChartStore()
