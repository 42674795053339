// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TopProducts-view-782a{opacity:1;transition:opacity 200ms ease-in-out}.TopProducts-hide-782a{opacity:.1;transition-duration:1000ms}.TopProducts-table-782a{width:100%;border-collapse:collapse}.TopProducts-table-782a td:nth-child(2){width:100px}.TopProducts-table-782a td:nth-child(3){width:100px}.TopProducts-table-782a td:nth-child(4){width:100px}.TopProducts-table-782a td:nth-child(5){width:100px}.TopProducts-table-782a thead td{white-space:nowrap;vertical-align:top;padding:12px 8px;font-size:13px;font-weight:600;color:var(--ui-text-color);background:rgba(30,30,30,.04);position:relative}.TopProducts-table-782a thead td+td:before{content:"";position:absolute;width:1px;left:0;top:8px;bottom:8px;background:#e4e4e4}.TopProducts-table-782a thead td:first-child{border-radius:8px 0 0 0}.TopProducts-table-782a thead td:last-child{border-radius:0 8px 0 0}.TopProducts-table-782a tbody td{vertical-align:center;padding:8px 8px;border-bottom:1px solid var(--ui-border-color-tertiary)}`, "",{"version":3,"sources":["webpack://./src/saas/page/statistics/items/TopProducts.module.scss"],"names":[],"mappings":"AAAA,uBACE,SAAA,CACA,oCAAA,CAGF,uBACE,UAAA,CACA,0BAAA,CAGF,wBACE,UAAA,CACA,wBAAA,CAIA,wCACE,WAAA,CAGF,wCACE,WAAA,CAGF,wCACE,WAAA,CAGF,wCACE,WAAA,CAKF,iCACE,kBAAA,CACA,kBAAA,CACA,gBAAA,CACA,cAAA,CACA,eAAA,CACA,0BAAA,CACA,6BAAA,CACA,iBAAA,CAGE,2CACE,UAAA,CACA,iBAAA,CACA,SAAA,CACA,MAAA,CACA,OAAA,CACA,UAAA,CACA,kBAAA,CAKJ,6CACE,uBAAA,CAGF,4CACE,uBAAA,CAMJ,iCACE,qBAAA,CACA,eAAA,CACA,uDAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"view": `TopProducts-view-782a`,
	"hide": `TopProducts-hide-782a`,
	"table": `TopProducts-table-782a`
};
module.exports = ___CSS_LOADER_EXPORT___;
