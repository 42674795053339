import { AppSvg } from 'app/config/icon'
import { getAppTitle } from 'app/config/title'
import IconHome from 'asset/icons/icon-home.svg'
import { Link } from 'component/Link'
import { Tooltip } from 'component/Popover/Tooltip'
import { Space } from 'component/Space'
import { observer } from 'mobx-react-lite'
import { sidebar } from 'saas/store/SidebarStore'
import css from './Logo.module.scss'

export const Logo = observer(() => {
  const { open } = sidebar
  const tooltip = getAppTitle()

  if (!open) return <Tooltip title={tooltip} right>
    <Link path="/">
      <Space height={72}>
        <Space className={css.home} center>
          <IconHome width={16} />
        </Space>
      </Space>
    </Link>
  </Tooltip>

  return <Link className={css.large} path="/">
    <Space className={css.logo} gap={8} pad={[0, 24]} center>
      <AppSvg w={120} />
      <div className={css.sep} />
      <div className={css.title}>SAAS</div>
    </Space>
  </Link>
})
