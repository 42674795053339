// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PageHeader-pageHeader-9874{border-bottom:1px solid #e4e4e4;margin-bottom:24px;padding:13px 0 26px}`, "",{"version":3,"sources":["webpack://./src/component/PageHeader/PageHeader.module.scss"],"names":[],"mappings":"AAAA,4BACE,+BAAA,CACA,kBAAA,CACA,mBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageHeader": `PageHeader-pageHeader-9874`
};
module.exports = ___CSS_LOADER_EXPORT___;
