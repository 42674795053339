import { DatePicker } from 'antd'
import { Button } from 'component/Button'
import { Space } from 'component/Space'
import { isDayjs } from 'dayjs'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { statistics } from 'saas/store/stat/StatisticsStore'
import css from './IntervalSelect.module.scss'

const { RangePicker } = DatePicker

export const IntervalSelect = observer(() => {
  const { interval } = statistics
  const { value, week, month, quarter, today } = interval

  const onChange = useLatestCallback((values: unknown[] | null) => {
    const [start, end] = values ?? []
    interval.start = isDayjs(start) ? start : undefined
    interval.end = isDayjs(end) ? end : undefined
  })

  const onToday = useLatestCallback(() => {
    interval.today = true
  })

  const onWeek = useLatestCallback(() => {
    interval.week = true
  })

  const onMonth = useLatestCallback(() => {
    interval.month = true
  })

  const onQuarter = useLatestCallback(() => {
    interval.quarter = true
  })

  return <Space gap={12} wide pad={8} className={css.wrapper}>
    <Space gap={8}>
      <Button className={css.button} text active={today} action={onToday}>
        {i18n('interval.Today')}
      </Button>
      <Button className={css.button} text active={week} action={onWeek}>
        {i18n('interval.Week')}
      </Button>
      <Button className={css.button} text active={month} action={onMonth}>
        {i18n('interval.Month')}
      </Button>
      <Button className={css.button} text active={quarter} action={onQuarter}>
        {i18n('interval.Quarter')}
      </Button>
    </Space>
    <RangePicker className={css.input} popupClassName={css.dropdown} value={value} onChange={onChange} />
  </Space>
})
