import { makeAutoObservable, runInAction } from 'mobx'
import { api } from 'store/api'
import { StatRequest, TopItem } from 'type/Statistics'
import { skipAsync } from 'util/async'

export class TopItemsStore {
  ready = false
  error = false
  items: TopItem[] = []

  constructor() {
    makeAutoObservable(this)
  }

  reset() {
    this.ready = false
    this.error = false
    this.items = []
  }

  readonly update = skipAsync(async (request: StatRequest) => {
    runInAction(() => {
      this.ready = false
      this.error = false
    })
    try {
      const items = await api.getTopItems(request)
      runInAction(() => {
        this.items = items
        this.ready = true
      })
    } catch (e) {
      console.error('load failed', e)
      runInAction(() => this.error = true)
    }
  })

}

export const tops = new TopItemsStore()
