import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { ChartSerie, getSerieTitle } from 'saas/store/stat/ChartSerie'

type Props = {
  serie: ChartSerie
}

export function LegendItem({ serie }: Props) {
  const { id, color } = serie
  const title = getSerieTitle(id)

  return <Space gap={8}>
    <Space gap={2}>
      <Space width={8} height={2} style={{ backgroundColor: color }} />
      <Space width={8} height={8} round={4} style={{ backgroundColor: color }} />
      <Space width={8} height={2} style={{ backgroundColor: color }} />
    </Space>
    <Typo size={13}>{title}</Typo>
  </Space>
}
