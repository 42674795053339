import { Button } from 'component/Button'
import { Modal } from 'component/Modal'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { companiesSaas } from 'saas/store/CompaniesSaasStore'
import { companyEdit } from 'saas/store/CompanyEditStore'

export const CompanyDeleteModal = observer(() => {
  const onDelete = useLatestCallback(async () => {
    void companiesSaas.deleteCompany(companyEdit.it.company)
    companyEdit.it.companyDeleting = false
  })

  const onCancel = useLatestCallback(() => {
    companyEdit.it.companyDeleting = false
  })

  return <Modal title={i18n('company.DeleteCompany')} onCancel={onCancel}>
    <Vertical gap={12}>
      <Typo size={14}>{i18n('company.DeleteCompanyQuestion')}</Typo>
    </Vertical>
    <Space spread gap={12}>
      <Button filled action={onCancel}>
        {i18n('common.Cancel')}
      </Button>
      <Button error link action={onDelete}>
        {i18n('common.Delete')}
      </Button>
    </Space>
  </Modal>
})
