// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Back-button-eed6.Back-button-eed6{width:fit-content;height:40px;margin:-4px -12px;padding:0 24px 0 12px;color:var(--ui-text-color)}.Back-button-eed6.Back-button-eed6.Back-simple-eed6{font-size:24px;padding-right:0}.Back-button-eed6.Back-button-eed6 .ant-btn-icon{margin-right:2px !important}`, "",{"version":3,"sources":["webpack://./src/feature/back/Back.module.scss"],"names":[],"mappings":"AAAA,mCACE,iBAAA,CACA,WAAA,CACA,iBAAA,CACA,qBAAA,CACA,0BAAA,CAEA,oDACE,cAAA,CACA,eAAA,CAGF,iDACE,2BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `Back-button-eed6`,
	"simple": `Back-simple-eed6`
};
module.exports = ___CSS_LOADER_EXPORT___;
