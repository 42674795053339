// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Input-input-ffa9{display:flex;min-height:40px;cursor:text}.Input-input-ffa9 .ant-input-group{display:flex;min-height:100%}.Input-input-ffa9 .ant-input-group>*{display:flex;align-items:center;flex:0 0 auto;min-height:100%}.Input-input-ffa9 .ant-input-group .ant-input-group-addon{width:auto}.Input-input-ffa9 .ant-input-group .ant-input-affix-wrapper{flex:1 0 0;padding-block:0}.Input-input-ffa9:hover{background:var(--ui-input-background)}.Input-input-ffa9 input:read-only{color:var(--ui-text-color-secondary)}.Input-input-ffa9 .ant-input-suffix{line-height:0}.Input-input-ffa9 .ant-input-group-addon{padding:0 9px 0 9px;line-height:0;background-color:var(--ui-background-color)}.Input-input-ffa9.ant-input-group-wrapper-lg .ant-input-group-addon{padding:0 12px 0 12px}.Input-input-ffa9.ant-input-outlined.ant-input-status-error:not(.ant-input-disabled){background:var(--ui-input-background)}.Input-mono-ffa9{font-family:var(--ui-font-family-monospace),monospace;letter-spacing:-0.5px}.Input-borderless-ffa9,.Input-borderless-ffa9 .ant-input-group-addon,.Input-borderless-ffa9 .ant-input-outlined{border-color:var(--ui-border-color-tertiary)}.Input-wide-ffa9{width:100%}@media(width <= 320px){.Input-wide-ffa9{max-width:calc(100vw - 48px) !important}}`, "",{"version":3,"sources":["webpack://./src/component/Input/Input.module.scss"],"names":[],"mappings":"AAAA,kBACE,YAAA,CACA,eAAA,CACA,WAAA,CAEA,mCACE,YAAA,CACA,eAAA,CAEA,qCACE,YAAA,CACA,kBAAA,CACA,aAAA,CACA,eAAA,CAGF,0DACE,UAAA,CAGF,4DAEE,UAAA,CACA,eAAA,CAIJ,wBACE,qCAAA,CAGF,kCACE,oCAAA,CAGF,oCACE,aAAA,CAGF,yCACE,mBAAA,CACA,aAAA,CACA,2CAAA,CAGF,oEACE,qBAAA,CAGF,qFACE,qCAAA,CAIJ,iBACE,qDAAA,CACA,qBAAA,CAKE,gHACE,4CAAA,CAKN,iBACE,UAAA,CAEA,uBAHF,iBAII,uCAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `Input-input-ffa9`,
	"mono": `Input-mono-ffa9`,
	"borderless": `Input-borderless-ffa9`,
	"wide": `Input-wide-ffa9`
};
module.exports = ___CSS_LOADER_EXPORT___;
