import { DropdownItem } from 'component/Popover/Dropdown'
import { Typo } from 'component/Typo'
import { useLatestCallback } from 'hook/useLatestCallback'
import { observer } from 'mobx-react-lite'
import { SideSelect } from 'saas/screen/sidebar/SideSelect'
import { home } from 'store/home'
import { emdash } from 'util/typo'

export const CompanyItem = observer(() => {
  const company = home.company.optional
  const image = company?.company.logo_url
  const text = company?.title || emdash

  const items: DropdownItem[] = home.companies.companies.map(co => {
    const json = co.company
    const id = json.company_id
    const selected = co === company
    const title = co.title
    const label = <Typo primary={selected}>{title}</Typo>
    return { key: id, label }
  })

  const onSelect = useLatestCallback((id: string) => {
    const company = home.companies.findCompany(id)
    if (company) void home.openCompany(company)
  })

  return <SideSelect image={image} text={text} items={items} onClick={onSelect} placement="bottomLeft" />
})
