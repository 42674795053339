// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ExpandItem-buttonView-3016{background:rgba(0,0,0,0);border-color:rgba(0,0,0,0);text-align:left;color:var(--sidebar-text-color);font-size:14px;padding:8px;height:48px;min-height:48px;display:flex;align-items:center;flex-wrap:nowrap;transition:all .15s ease-in-out;cursor:pointer}.ExpandItem-buttonView-3016:hover .ExpandItem-buttonViewTitle-3016{color:var(--sidebar-text-color-hover)}.ExpandItem-buttonView-3016:hover .ExpandItem-buttonViewIcon-3016{background:var(--sidebar-item-icon-background-hover)}.ExpandItem-buttonView-3016:hover .ExpandItem-buttonViewIcon-3016 svg path{fill:var(--sidebar-background)}.ExpandItem-buttonView-3016 .ExpandItem-buttonViewTitle-3016{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;transition:all .15s ease-in-out}.ExpandItem-buttonView-3016 .ExpandItem-buttonViewIcon-3016{width:32px;height:32px;min-width:32px;border-radius:8px;background:var(--sidebar-item-icon-background);margin-right:12px;display:flex;align-items:center;justify-content:center;transition:all .15s ease-in-out}`, "",{"version":3,"sources":["webpack://./src/saas/screen/sidebar/ExpandItem.module.scss"],"names":[],"mappings":"AAAA,4BACE,wBAAA,CACA,0BAAA,CACA,eAAA,CACA,+BAAA,CACA,cAAA,CACA,WAAA,CACA,WAAA,CACA,eAAA,CACA,YAAA,CACA,kBAAA,CACA,gBAAA,CACA,+BAAA,CACA,cAAA,CAIE,mEACE,qCAAA,CAGF,kEACE,oDAAA,CAEA,2EACE,8BAAA,CAKN,6DACE,kBAAA,CACA,eAAA,CACA,sBAAA,CACA,+BAAA,CAGF,4DACE,UAAA,CACA,WAAA,CACA,cAAA,CACA,iBAAA,CACA,8CAAA,CACA,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,+BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonView": `ExpandItem-buttonView-3016`,
	"buttonViewTitle": `ExpandItem-buttonViewTitle-3016`,
	"buttonViewIcon": `ExpandItem-buttonViewIcon-3016`
};
module.exports = ___CSS_LOADER_EXPORT___;
