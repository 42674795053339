import { Tooltip as TooltipAntd } from 'antd'
import { clsx } from 'clsx'
import { ReactNode } from 'react'
import css from './Tooltip.module.scss'

type Props = {
  children: ReactNode
  className?: string
  right?: boolean
  title?: ReactNode
}

function getOffset(placement: string, space: number): [number, number] {
  if (placement.startsWith('top')) {
    return [0, -space]
  } else if (placement.startsWith('bottom')) {
    return [0, space]
  } else if (placement.startsWith('left')) {
    return [-space, 0]
  } else if (placement.startsWith('right')) {
    return [space, 0]
  }
  console.error('unexpected placement', placement)
  return [0, 0]
}

export function Tooltip(props: Props) {
  const { children, right, title } = props
  const className = clsx(props.className, css.tooltip)
  const placement = right ? 'right' : 'top'
  const align = { offset: getOffset(placement, 8) }

  return <TooltipAntd  rootClassName={className} title={title} placement={placement} align={align}
    transitionName={''} arrow={false} mouseLeaveDelay={0} mouseEnterDelay={0.200}>
    <div className={css.child}>{children}</div>
  </TooltipAntd>
}
