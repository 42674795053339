// https://stackoverflow.com/a/57102881/658606

const SUN = 0
const MON = 1
const SAT = 6

function weekStart(region: string, language: string): number {
  // noinspection SpellCheckingInspection
  const regionSat: string[] = 'AEAFBHDJDZEGIQIRJOKWLYOMQASDSY'.match(/../g) ?? []
  const regionSun: string[] = 'AGARASAUBDBRBSBTBWBZCACNCODMDOETGTGUHKHNIDILINJMJPKEKHKRLAMHMMMOMTMXMZNINPPAPEPHPKPRPTPYSASGSVTHTTTWUMUSVEVIWSYEZAZW'.match(/../g) ?? []
  const languageSat: string[] = ['ar', 'arq', 'arz', 'fa']
  const languageSun: string[] = 'amasbndzengnguhehiidjajvkmknkolomhmlmrmtmyneomorpapssdsmsnsutatethtnurzhzu'.match(/../g) ?? []

  if (region) return regionSun.includes(region) ? SUN : regionSat.includes(region) ? SAT : MON
  if (language) return languageSun.includes(language) ? SUN : languageSat.includes(language) ? SAT : MON
  return SUN
}

function weekStartLocale(locale: string): number {
  const parts: string[] = locale.match(/^([a-z]{2,3})(?:-([a-z]{3})(?=$|-))?(?:-([a-z]{4})(?=$|-))?(?:-([a-z]{2}|\d{3})(?=$|-))?/i) ?? []
  return weekStart(parts[4] ?? '', parts[1] ?? '')
}

export function getLocalFirstDay() {
  return weekStartLocale(navigator.language)
}
