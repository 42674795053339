// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserRow-remove-90be{width:40px;min-width:40px;height:40px;padding:0}.UserRow-remove-90be span{display:flex;align-items:center;justify-content:center}`, "",{"version":3,"sources":["webpack://./src/page/invite/UserRow.module.scss"],"names":[],"mappings":"AAAA,qBACE,UAAA,CACA,cAAA,CACA,WAAA,CACA,SAAA,CAEA,0BACE,YAAA,CACA,kBAAA,CACA,sBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"remove": `UserRow-remove-90be`
};
module.exports = ___CSS_LOADER_EXPORT___;
