// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KeyBox-keyBox-c6e3:nth-child(odd){background:#fbfbfb}`, "",{"version":3,"sources":["webpack://./src/saas/page/company/manage/KeyBox.module.scss"],"names":[],"mappings":"AACE,mCACE,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"keyBox": `KeyBox-keyBox-c6e3`
};
module.exports = ___CSS_LOADER_EXPORT___;
