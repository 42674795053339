// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Divider-horizontal-a748{width:100%;height:0;border-bottom:1px solid var(--ui-border-color)}.Divider-vertical-a748{width:1px;height:0;border-right:1px solid var(--ui-border-color)}.Divider-secondary-a748{border-color:var(--ui-border-color-secondary)}.Divider-tertiary-a748{border-color:var(--ui-border-color-tertiary)}`, "",{"version":3,"sources":["webpack://./src/component/Space/Divider.module.scss"],"names":[],"mappings":"AAAA,yBACE,UAAA,CACA,QAAA,CACA,8CAAA,CAGF,uBACE,SAAA,CACA,QAAA,CACA,6CAAA,CAGF,wBACE,6CAAA,CAGF,uBACE,4CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"horizontal": `Divider-horizontal-a748`,
	"vertical": `Divider-vertical-a748`,
	"secondary": `Divider-secondary-a748`,
	"tertiary": `Divider-tertiary-a748`
};
module.exports = ___CSS_LOADER_EXPORT___;
